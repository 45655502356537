<template>
  <div id="app">
    <p>
        <router-link to="/antadmin">用户管理</router-link> | 
        <router-link to="/antadmin/record">内容审核</router-link> | 
        <router-link to="/antadmin/feedback">意见反馈</router-link> | 
        <router-link to="/antadmin/statistics">互动统计</router-link>
    </p>
    <a-locale-provider :locale="zh_CN">
    <router-view/>
    </a-locale-provider>

    
  </div>
</template>

<script>
  import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
  import moment from 'moment';
  import 'moment/locale/zh-cn';

  moment.locale('zh-cn');
  export default {
    data() {
      return {
        zh_CN,
      };
    },
  };
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
