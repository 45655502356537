import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Antd from 'ant-design-vue'

import 'ant-design-vue/dist/antd.css';
// import VCharts from 'v-charts'

// Vue.use(VCharts)
Vue.component(window.VeIndex);
// Vue.component("ve-histogram", window.VueECharts);
Vue.config.productionTip = false

Vue.use(Antd)
Vue.use(window.VueVideoPlayer)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
